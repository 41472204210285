export interface AppConfig {
    REACT_APP_MSAL_CLIENT_ID: string;
    REACT_APP_MSAL_TENANT_ID: string;
    REACT_APP_MSAL_SIGNUPSIGNIN_POLICY: string;
    REACT_APP_MSAL_FORGOTPASSWORD_POLICY: string;
    REACT_APP_MSAL_EDITPROFILE_POLICY: string;
    REACT_APP_MSAL_POLICY_AUTHORITY_ROOT: string;
    REACT_APP_MSAL_B2C_AUTHORITYDOMAIN: string;
    REACT_APP_BASEURL: string;
    REACT_APP_BFF_API_BASEURL: string;
    REACT_APP_BFF_API_SCOPES: string;
    REACT_APP_ENV: string
}

export function getAppConfig() : AppConfig {
    return (window as any)["runConfig"] as AppConfig;
}